//--------TYPES--------
import {
  TM_RENEWALS,
  RENEWAL_FILED,
  REPLY_TO_CORRESPONDENCE,
  PSEUDO_REPLY_TO_CORRESPONDENCE,
  OPPOSED_REPLY_TO_CORRESPONDENCE,
  PSEUDO_OPPOSED_REPLY_TO_CORRESPONDENCE,
  PENDING_EVIDENCE,
  PSEUDO_PENDING_EVIDENCE,
  OPPOSED_PENDING_EVIDENCE,
  PSEUDO_OPPOSED_PENDING_EVIDENCE,
  REPLY_FILED_TO_CORRESPONDENCE,
  REPLY_FILED_TO_CORRESPONDENCE_NEW,
  TOTAL_TM,
  TOTAL_TM_PROPRIETOR_FILTERS,
  TOTAL_TM_CLASSES_FILTERS,
  TOTAL_TM_TRADEMARK_TYPE_FILTERS,
  TOTAL_TM_TAG_FILTERS,
  TOTAL_TM_TRADEMARK_STATUS_FILTERS,
  TOTAL_TM_TRADEMARK_STATES_FILTERS,
  TOTAL_TM_TRADEMARK_OPPONENT_FILTERS,
  TOTAL_TM_JOURNAL_FILTERS,
  TOTAL_TM_ALERTS_FILTERS,
  TOTAL_TM_TRADEMARK_OFFICE_FILTERS,
  REFINED_TRADEMARK,
  TRADEMARK_CHANGELOG,
  PROPRIETOR,
  PROPRIETOR_DETAIL,
  TLA_HEARING,
  OPPOSITION_HEARING,
  OPPOSED_TLA_HEARING,
  OPPOSED_OPPOSITION_HEARING,
  TLA_HEARING_CLASSES_FILTERS,
  TLA_HEARING_PROPRIETORS_FILTERS,
  TLA_HEARING_LOCATION_FILTERS,
  TLA_HEARING_OFFICERS_FILTERS,
  TLA_HEARING_OFFICE_FILTERS,
  TLA_HEARING_TAG_FILTERS,
  OPPOSITION_HEARING_CLASSES_FILTERS,
  OPPOSITION_HEARING_PROPRIETORS_FILTERS,
  OPPOSITION_HEARING_LOCATION_FILTERS,
  OPPOSITION_HEARING_OFFICERS_FILTERS,
  OPPOSITION_HEARING_TAG_FILTERS,
  MANAGE_TRADEMARK,
  POST_MANAGE_TRADEMARK,
  UPLOAD_FILE_TM,
  UPLOAD_FILE_TM_REFINED,
  OPPOSITION_HEARING_OFFICE_FILTERS,
} from "../../../actions/managerActions/trademarkDashboard/types";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../../constants/commonConstants/generalConstants";
import {
  TM_DefaultSelectedFilters,
  Hearing_DefaultSelectedFilters,
} from "../../../../constants/managerConstants/trademarkConstants";

export default function (
  state = {
    pseudo: false,

    tmRenewal: null,
    tmRenewalStatus: null,
    tmRenewalPageNumber: 1,

    renewalFiledMessage: "",
    renewalFiledStatus: null,

    replyToCorrespondence: null,
    replyToCorrespondenceStatus: null,
    replyToCorrespondenceAppliedFilters: {
      ...TM_DefaultSelectedFilters,
      filterFlag: false,
    },
    replyToCorrespondencePageNumber: 1,

    pseudoReplyToCorrespondence: null,
    pseudoReplyToCorrespondenceStatus: null,
    pseudoReplyToCorrespondenceAppliedFilters: {
      ...TM_DefaultSelectedFilters,
      filterFlag: false,
    },
    pseudoReplyToCorrespondencePageNumber: 1,

    opposedReplyToCorrespondence: null,
    opposedReplyToCorrespondenceStatus: null,
    opposedReplyToCorrespondenceAppliedFilters: {
      ...TM_DefaultSelectedFilters,
      filterFlag: false,
    },
    opposedReplyToCorrespondencePageNumber: 1,

    pseudoOpposedReplyToCorrespondence: null,
    pseudoOpposedReplyToCorrespondenceStatus: null,
    pseudoOpposedReplyToCorrespondenceAppliedFilters: {
      ...TM_DefaultSelectedFilters,
      filterFlag: false,
    },
    pseudoOpposedReplyToCorrespondencePageNumber: 1,

    pendingEvidence: null,
    pendingEvidenceStatus: null,
    pendingEvidencePageNumber: 1,

    pseudoPendingEvidence: null,
    pseudoPendingEvidenceStatus: null,
    pseudoPendingEvidencePageNumber: 1,

    opposedPendingEvidence: null,
    opposedPendingEvidenceStatus: null,
    opposedPendingEvidencePageNumber: 1,

    pseudoOpposedPendingEvidence: null,
    pseudoOpposedPendingEvidenceStatus: null,
    pseudoOpposedPendingEvidencePageNumber: 1,

    replyFiledMessage: null,
    replyFiledStatus: null,

    // totalTrademarks: null,
    // totalTrademarksStatus: null,
    // totalTrademarksAppliedFilters: {
    //     ...TM_DefaultSelectedFilters,
    //     filterFlag: false
    // },
    // totalTrademarksPageNumber: 1,

    // pseudoTotalTrademarks: null,
    // pseudoTotalTrademarksStatus: null,
    // pseudoTotalTrademarksAppliedFilters: {
    //     ...TM_DefaultSelectedFilters,
    //     filterFlag: false
    // },
    // pseudoTotalTrademarksPageNumber: 1,

    // opposedTotalTrademarks: null,
    // opposedTotalTrademarksStatus: null,
    // opposedTotalTrademarksAppliedFilters: {
    //     ...TM_DefaultSelectedFilters,
    //     filterFlag: false
    // },
    // opposedTotalTrademarksPageNumber: 1,

    // pseudoOpposedTotalTrademarks: null,
    // pseudoOpposedTotalTrademarksStatus: null,
    // pseudoOpposedTotalTrademarksAppliedFilters: {
    //     ...TM_DefaultSelectedFilters,
    //     filterFlag: false
    // },
    // pseudoOpposedTotalTrademarksPageNumber: 1,

    // statusTrackerTotalTrademarks: null,
    // statusTrackerTotalTrademarksStatus: null,
    // statusTrackerTotalTrademarksAppliedFilters: {
    //     ...TM_DefaultSelectedFilters,
    //     filterFlag: false
    // },
    // statusTrackerTotalTrademarksPageNumber: 1,

    // pseudoStatusTrackerTotalTrademarks: null,
    // pseudoStatusTrackerTotalTrademarksStatus: null,
    // pseudoStatusTrackerTotalTrademarksAppliedFilters: {
    //     ...TM_DefaultSelectedFilters,
    //     filterFlag: false
    // },
    // pseudoStatusTrackerTotalTrademarksPageNumber: 1,

    refinedTrademark: null,
    refinedTrademarkStatus: null,
    trademarkChangelog: null,
    trademarkChangelogStatus: null,

    proprietor: null,
    proprietorStatus: null,
    proprietorDetail: null,
    proprietorDetailStatus: null,

    upcomingHearings: false,
    tla: false,

    tlaHearing: null,
    tlaHearingStatus: null,
    tlaHearingLastAppliedFilters: {
      ...Hearing_DefaultSelectedFilters,
      filterFlag: false,
    },
    tlaHearingPageNumber: 1,

    tlaHearingFiltersStatus: null,

    tlaHearingClassFilters: [],
    tlaHearingClassFiltersCount: 0,
    tlaHearingProprietorsFilters: [],
    tlaHearingProprietorsFiltersCount: 0,
    tlaHearingOfficersFilters: [],
    tlaHearingOfficersFiltersCount: 0,

    tlaHearingOfficeFilters: [],
    tlaHearingOfficeFiltersCount: 0,
    tlaHearingTagsFilters: [],
    tlaHearingTagsFiltersCount: 0,
    tlaHearingLocationFilters: [],
    tlaHearingLocationFiltersCount: 0,

    oppositionHearingFiltersStatus: null,
    oppositionHearingClassFilters: [],
    oppositionHearingClassFiltersCount: 0,
    oppositionHearingProprietorsFilters: [],
    oppositionHearingProprietorsFiltersCount: 0,
    oppositionHearingTagFilters: [],
    oppositionHearingTagFiltersCount: 0,
    oppositionHearingLocationFilters: [],
    oppositionHearingLocationFiltersCount: 0,
    report_columns: [],

    oppositionHearing: null,
    oppositionHearingStatus: null,
    oppositionHearingLastAppliedFilters: {
      ...Hearing_DefaultSelectedFilters,
      filterFlag: false,
    },
    oppositionHearingPageNumber: 1,

    opposedTlaHearing: null,
    opposedTlaHearingStatus: null,
    opposedTlaHearingLastAppliedFilters: {
      ...Hearing_DefaultSelectedFilters,
      filterFlag: false,
    },
    opposedTlaHearingPageNumber: 1,

    opposedOppositionHearing: null,
    opposedOppositionHearingStatus: null,
    opposedOppositionHearingLastAppliedFilters: {
      ...Hearing_DefaultSelectedFilters,
      filterFlag: false,
    },
    opposedOppositionHearingPageNumber: 1,

    manageTrademarkType: "trademark",
    manageTrademark: null,
    manageTrademarkStatus: null,
    manageTrademarkLastAppliedFilters: {
      ...TM_DefaultSelectedFilters,
      filterFlag: false,
    },
    manageTrademarkNumber: 1,
    deleteManageTrademark: null,
    deleteManageTrademarkStatus: null,
    editManageTrademarkStatus: null,

    //These 2 objects work for all 3 dashboards : Total Application, 3rd Party Total Application and Status Tracker
    totalTMState: {
      totalTrademarksPageNumber: 1,
      totalTrademarks: null,
      totalTrademarksStatus: null,
      totalTrademarksAppliedFilters: {
        ...TM_DefaultSelectedFilters,
        filterFlag: false,
      },
      report_columns: [],
      filters: {
        CLASSESFilterData: [],
        PROPRIETORFilterData: [],
        TRADEMARK_TYPEFilterData: [],
        TAGFilterData: [],
        TRADEMARK_STATUSFilterData: [],
        TRADEMARK_STATESFilterData: [],
        TRADEMARK_OPPONENTFilterData: [],
        JOURNALFilterData: [],
        ALERTSFilterData: [],
        TRADEMARK_OFFICEFilterData: [],

        CLASSESFilterDataCount: 0,
        PROPRIETORFilterDataCount: 0,
        TRADEMARK_TYPEFilterDataCount: 0,
        TAGFilterDataCount: 0,
        TRADEMARK_STATUSFilterDataCount: 0,
        TRADEMARK_STATESFilterDataCount: 0,
        TRADEMARK_OPPONENTFilterDataCount: 0,
        JOURNALFilterDataCount: 0,
        ALERTSFilterDataCount: 0,
        TRADEMARK_OFFICEFilterDataCount: 0,

        CLASSESFilterStatus: null,
        PROPRIETORFilterStatus: null,
        TRADEMARK_TYPEFilterStatus: null,
        TAGFilterStatus: null,
        TRADEMARK_STATUSFilterStatus: null,
        TRADEMARK_STATESFilterStatus: null,
        TRADEMARK_OPPONENTFilterStatus: null,
        JOURNALFilterStatus: null,
        ALERTSFilterStatus: null,
        TRADEMARK_OFFICEFilterStatus: null,
      },
    },
    pseudoTMState: {
      pseudoTotalTrademarks: null,
      pseudoTotalTrademarksStatus: null,
      pseudoTotalTrademarksAppliedFilters: {
        ...TM_DefaultSelectedFilters,
        filterFlag: false,
      },
      pseudoTotalTrademarksPageNumber: 1,
      report_columns: [],
      filters: {
        CLASSESFilterData: [],
        PROPRIETORFilterData: [],
        TRADEMARK_TYPEFilterData: [],
        TAGFilterData: [],
        TRADEMARK_STATUSFilterData: [],
        TRADEMARK_STATESFilterData: [],
        TRADEMARK_OPPONENTFilterData: [],
        JOURNALFilterData: [],
        ALERTSFilterData: [],
        TRADEMARK_OFFICEFilterData: [],

        CLASSESFilterDataCount: 0,
        PROPRIETORFilterDataCount: 0,
        TRADEMARK_TYPEFilterDataCount: 0,
        TAGFilterDataCount: 0,
        TRADEMARK_STATUSFilterDataCount: 0,
        TRADEMARK_STATESFilterDataCount: 0,
        TRADEMARK_OPPONENTFilterDataCount: 0,
        JOURNALFilterDataCount: 0,
        ALERTSFilterDataCount: 0,
        TRADEMARK_OFFICEFilterDataCount: 0,

        CLASSESFilterStatus: null,
        PROPRIETORFilterStatus: null,
        TRADEMARK_TYPEFilterStatus: null,
        TAGFilterStatus: null,
        TRADEMARK_STATUSFilterStatus: null,
        TRADEMARK_STATESFilterStatus: null,
        TRADEMARK_OPPONENTFilterStatus: null,
        JOURNALFilterStatus: null,
        ALERTSFilterStatus: null,
        TRADEMARK_OFFICEFilterStatus: null,
      },
    },
  },
  action,
) {
  switch (action.type) {
    case TM_RENEWALS:
      return {
        ...state,
        tmRenewal: action.payload,
        tmRenewalStatus: action.status,
        tmRenewalPageNumber: action.pageNumber,
      };

    case RENEWAL_FILED:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            renewalFiledStatus: LOADING,
          };

        case SUCCESS:
          return {
            ...state,
            renewalFiledMessage: action.payload,
            renewalFiledStatus: SUCCESS,
            tmRenewal: {
              ...state.tmRenewal,
              ip_india: {
                ...state.tmRenewal.ip_india,
                trademarks: state.tmRenewal.ip_india.trademarks.filter(
                  (item) => item.id !== action.payload.trademark_number,
                ),
              },
              pseudo: {
                ...state.tmRenewal.pseudo,
                trademarks: state.tmRenewal.pseudo.trademarks.filter(
                  (item) => item.id !== action.payload.trademark_number,
                ),
              },
            },
          };

        case ERROR:
          return {
            ...state,
            renewalFiledStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case REPLY_TO_CORRESPONDENCE:
      return {
        ...state,
        replyToCorrespondence: action.payload,
        replyToCorrespondenceStatus: action.status,
        replyToCorrespondenceAppliedFilters: action.lastAppliedFilters,
        replyToCorrespondencePageNumber: action.pageNumber,
      };

    case PSEUDO_REPLY_TO_CORRESPONDENCE:
      return {
        ...state,
        pseudoReplyToCorrespondence: action.payload,
        pseudoReplyToCorrespondenceStatus: action.status,
        pseudoReplyToCorrespondenceAppliedFilters: action.lastAppliedFilters,
        pseudo: action.pseudo,
        pseudoReplyToCorrespondencePageNumber: action.pageNumber,
      };

    case OPPOSED_REPLY_TO_CORRESPONDENCE:
      return {
        ...state,
        opposedReplyToCorrespondence: action.payload,
        opposedReplyToCorrespondenceStatus: action.status,
        opposedReplyToCorrespondenceAppliedFilters: action.lastAppliedFilters,
        opposedReplyToCorrespondencePageNumber: action.pageNumber,
      };

    case PSEUDO_OPPOSED_REPLY_TO_CORRESPONDENCE:
      return {
        ...state,
        pseudoOpposedReplyToCorrespondence: action.payload,
        pseudoOpposedReplyToCorrespondenceStatus: action.status,
        pseudoOpposedReplyToCorrespondenceAppliedFilters:
          action.lastAppliedFilters,
        pseudo: action.pseudo,
        pseudoOpposedReplyToCorrespondencePageNumber: action.pageNumber,
      };

    case PENDING_EVIDENCE:
      return {
        ...state,
        pendingEvidence: action.payload,
        pendingEvidenceStatus: action.status,
        pendingEvidencePageNumber: action.pageNumber,
      };

    case PSEUDO_PENDING_EVIDENCE:
      return {
        ...state,
        pseudoPendingEvidence: action.payload,
        pseudoPendingEvidenceStatus: action.status,
        pseudo: action.pseudo,
        pseudoPendingEvidenceAppliedFilters: action.lastAppliedFilters,
        pseudoPendingEvidencePageNumber: action.pageNumber,
      };

    case OPPOSED_PENDING_EVIDENCE:
      return {
        ...state,
        opposedPendingEvidence: action.payload,
        opposedPendingEvidenceStatus: action.status,
        opposedPendingEvidencePageNumber: action.pageNumber,
      };

    case PSEUDO_OPPOSED_PENDING_EVIDENCE:
      return {
        ...state,
        pseudoOpposedPendingEvidence: action.payload,
        pseudoOpposedPendingEvidenceStatus: action.status,
        pseudo: action.pseudo,
        pseudoOpposedPendingEvidenceAppliedFilters: action.lastAppliedFilters,
        pseudoOpposedPendingEvidencePageNumber: action.pageNumber,
      };

    case REPLY_FILED_TO_CORRESPONDENCE:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            replyFiledStatus: LOADING,
          };

        case SUCCESS:
          if (action.mm_type === "reply_to_correspondance") {
            return {
              ...state,
              replyFiledMessage: action.payload,
              replyFiledStatus: SUCCESS,
              replyToCorrespondence: {
                ...state.replyToCorrespondence,
                ip_india: {
                  ...state.replyToCorrespondence?.ip_india,
                  trademarks:
                    state.replyToCorrespondence?.ip_india?.trademarks?.filter(
                      (item) => item.id !== action.payload.trademark_number,
                    ),
                },
              },
              pseudoReplyToCorrespondence: {
                ...state.pseudoReplyToCorrespondence,
                pseudo: {
                  ...state.pseudoReplyToCorrespondence?.pseudo,
                  trademarks:
                    state.pseudoReplyToCorrespondence?.pseudo?.trademarks?.filter(
                      (item) => item.id !== action.payload.trademark_number,
                    ),
                },
              },
            };
          } else if (action.mm_type === "opposed_reply_to_correspondance") {
            return {
              ...state,
              replyFiledMessage: action.payload,
              replyFiledStatus: SUCCESS,
              opposedReplyToCorrespondence: {
                ...state.opposedReplyToCorrespondence,
                ip_india: {
                  ...state.opposedReplyToCorrespondence?.ip_india,
                  trademarks:
                    state.opposedReplyToCorrespondence?.ip_india?.trademarks?.filter(
                      (item) => item.id !== action.payload.trademark_number,
                    ),
                },
              },
              pseudoOpposedReplyToCorrespondence: {
                ...state.pseudoOpposedReplyToCorrespondence,
                pseudo: {
                  ...state.pseudoOpposedReplyToCorrespondence?.pseudo,
                  trademarks:
                    state.pseudoOpposedReplyToCorrespondence?.pseudo?.trademarks?.filter(
                      (item) => item.id !== action.payload.trademark_number,
                    ),
                },
              },
            };
          }
          break;
        case ERROR:
          return {
            ...state,
            replyFiledStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case REPLY_FILED_TO_CORRESPONDENCE_NEW:
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            replyFiledStatus: LOADING,
          };

        case SUCCESS:
          if (action.pseudo) {
            return {
              ...state,
              replyFiledMessage: action.payload,
              replyFiledStatus: SUCCESS,
              pseudoTMState: {
                ...state.pseudoTMState,
                totalTrademarks:
                  state.pseudoTMState.totalTrademarks.pseudo.trademarks.filter(
                    (item) => item.id !== action.payload.trademark_number,
                  ),
              },
            };
          }

          return {
            ...state,
            replyFiledMessage: action.payload,
            replyFiledStatus: SUCCESS,
            totalTMState: {
              ...state.totalTMState,
              totalTrademarks:
                state.totalTMState.totalTrademarks.ip_india.trademarks.filter(
                  (item) => item.id !== action.payload.trademark_number,
                ),
            },
          };
        case ERROR:
          return {
            ...state,
            replyFiledStatus: ERROR,
          };

        default:
          break;
      }
      break;

    case TOTAL_TM:
      if (action.status === LOADING) {
        if (action.pseudo) {
          return {
            ...state,
            pseudoTMState: {
              ...state.pseudoTMState,
              pseudoTotalTrademarksStatus: action.status,
            },
          };
        }
        return {
          ...state,
          totalTMState: {
            ...state.totalTMState,
            totalTrademarksStatus: action.status,
          },
        };
      }
      if (action.pseudo) {
        return {
          ...state,
          pseudoTMState: {
            ...state.pseudoTMState,
            pseudoTotalTrademarks: action.payload,
            pseudoTotalTrademarksStatus: action.status,
            pseudoTotalTrademarksAppliedFilters: action.lastAppliedFilters,
            pseudoTotalTrademarksPageNumber: action.pageNumber,
            // report_columns:action.payload?.report_columns
          },
        };
      }
      return {
        ...state,
        totalTMState: {
          ...state.totalTMState,
          totalTrademarks: action.payload,
          totalTrademarksStatus: action.status,
          totalTrademarksAppliedFilters: action.lastAppliedFilters,
          totalTrademarksPageNumber: action.pageNumber,
          // report_columns:action.payload?.report_columns
        },
      };

    case TOTAL_TM_CLASSES_FILTERS:
      if (action.pseudo) {
        return {
          ...state,
          pseudoTMState: {
            ...state.pseudoTMState,
            filters: {
              ...state.pseudoTMState.filters,
              CLASSESFilterData: action.payload?.pseudo?.filters?.data || [],
              CLASSESFilterDataCount:
                action.payload?.pseudo?.filters?.count || 0,
              CLASSESFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      return {
        ...state,
        totalTMState: {
          ...state.totalTMState,
          filters: {
            ...state.totalTMState.filters,
            CLASSESFilterData: action.payload?.ip_india?.filters?.data || [],
            CLASSESFilterDataCount:
              action.payload?.ip_india?.filters?.count || 0,
            CLASSESFilterStatus: action.status,
            filterStatus: action.status,
          },
        },
      };
    case TOTAL_TM_PROPRIETOR_FILTERS:
      if (action.status === LOADING) {
        if (action.pseudo) {
          return {
            ...state,
            pseudoTMState: {
              ...state.pseudoTMState,
              filters: {
                ...state.pseudoTMState.filters,
                PROPRIETORFilterStatus: action.status,
                filterStatus: action.status,
              },
            },
          };
        }
        return {
          ...state,
          totalTMState: {
            ...state.totalTMState,
            filters: {
              ...state.totalTMState.filters,
              PROPRIETORFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }

      if (action.pseudo) {
        return {
          ...state,
          pseudoTMState: {
            ...state.pseudoTMState,
            filters: {
              ...state.pseudoTMState.filters,
              PROPRIETORFilterData: action.paginate
                ? [
                    ...new Set([
                      ...state.pseudoTMState.filters.PROPRIETORFilterData,
                      ...action.payload?.pseudo?.filters?.data,
                    ]),
                  ]
                : action.payload?.pseudo?.filters?.data,
              PROPRIETORFilterDataCount:
                action.payload?.pseudo?.filters?.count || 0,
              PROPRIETORFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      return {
        ...state,
        totalTMState: {
          ...state.totalTMState,
          filters: {
            ...state.totalTMState.filters,
            PROPRIETORFilterData: action.paginate
              ? [
                  ...new Set([
                    ...state.totalTMState.filters.PROPRIETORFilterData,
                    ...action.payload?.ip_india?.filters?.data,
                  ]),
                ]
              : action.payload?.ip_india?.filters?.data,
            PROPRIETORFilterDataCount:
              action.payload?.ip_india?.filters?.count || 0,
            PROPRIETORFilterStatus: action.status,
            filterStatus: action.status,
          },
        },
      };
    case TOTAL_TM_TRADEMARK_TYPE_FILTERS:
      if (action.pseudo) {
        return {
          ...state,
          pseudoTMState: {
            ...state.pseudoTMState,
            filters: {
              ...state.pseudoTMState.filters,
              TRADEMARK_TYPEFilterData:
                action.payload?.pseudo?.filters?.data || [],
              TRADEMARK_TYPEFilterDataCount:
                action.payload?.pseudo?.filters?.count || 0,
              TRADEMARK_TYPEFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      return {
        ...state,

        totalTMState: {
          ...state.totalTMState,
          filters: {
            ...state.totalTMState.filters,
            TRADEMARK_TYPEFilterData:
              action.payload?.ip_india?.filters?.data || [],
            TRADEMARK_TYPEFilterDataCount:
              action.payload?.ip_india?.filters?.count || 0,
            TRADEMARK_TYPEFilterStatus: action.status,
            filterStatus: action.status,
          },
        },
      };
    case TOTAL_TM_TRADEMARK_STATUS_FILTERS:
      if (action.pseudo) {
        return {
          ...state,
          pseudoTMState: {
            ...state.pseudoTMState,
            filters: {
              ...state.pseudoTMState.filters,
              TRADEMARK_STATUSFilterData:
                action.payload?.pseudo?.filters?.data || [],
              TRADEMARK_STATUSFilterDataCount:
                action.payload?.pseudo?.filters?.count || 0,
              TRADEMARK_STATUSFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      return {
        ...state,

        totalTMState: {
          ...state.totalTMState,
          filters: {
            ...state.totalTMState.filters,
            TRADEMARK_STATUSFilterData:
              action.payload?.ip_india?.filters?.data || [],
            TRADEMARK_STATUSFilterDataCount:
              action.payload?.ip_india?.filters?.count || 0,
            TRADEMARK_STATUSFilterStatus: action.status,
            filterStatus: action.status,
          },
        },
      };

    case TOTAL_TM_TAG_FILTERS:
      if (action.status === LOADING) {
        if (action.pseudo) {
          return {
            ...state,
            pseudoTMState: {
              ...state.pseudoTMState,
              filters: {
                ...state.pseudoTMState.filters,
                TAGFilterStatus: action.status,
                filterStatus: action.status,
              },
            },
          };
        }
        return {
          ...state,
          totalTMState: {
            ...state.totalTMState,
            filters: {
              ...state.totalTMState.filters,
              TAGFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }

      if (action.pseudo) {
        return {
          ...state,
          pseudoTMState: {
            ...state.pseudoTMState,
            filters: {
              ...state.pseudoTMState.filters,
              TAGFilterData: action.paginate
                ? [
                    ...new Set([
                      ...state.pseudoTMState.filters.TAGFilterData,
                      ...action.payload?.pseudo?.filters?.data,
                    ]),
                  ]
                : action.payload?.pseudo?.filters?.data,
              TAGFilterDataCount: action.payload?.pseudo?.filters?.count || 0,
              TAGFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      return {
        ...state,

        totalTMState: {
          ...state.totalTMState,
          filters: {
            ...state.totalTMState.filters,
            TAGFilterData: action.paginate
              ? [
                  ...new Set([
                    ...state.totalTMState.filters.TAGFilterData,
                    ...action.payload?.ip_india?.filters?.data,
                  ]),
                ]
              : action.payload?.ip_india?.filters?.data,
            TAGFilterDataCount: action.payload?.ip_india?.filters?.count || 0,
            TAGFilterStatus: action.status,
            filterStatus: action.status,
          },
        },
      };
    case TOTAL_TM_TRADEMARK_STATES_FILTERS:
      if (action.pseudo) {
        return {
          ...state,
          pseudoTMState: {
            ...state.pseudoTMState,
            filters: {
              ...state.pseudoTMState.filters,
              TRADEMARK_STATESFilterData:
                action.payload?.pseudo?.filters?.data || [],
              TRADEMARK_STATESFilterDataCount:
                action.payload?.pseudo?.filters?.count || 0,
              TRADEMARK_STATESFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      return {
        ...state,

        totalTMState: {
          ...state.totalTMState,
          filters: {
            ...state.totalTMState.filters,
            TRADEMARK_STATESFilterData:
              action.payload?.ip_india?.filters?.data || [],
            TRADEMARK_STATESFilterDataCount:
              action.payload?.ip_india?.filters?.count || 0,
            TRADEMARK_STATESFilterStatus: action.status,
            filterStatus: action.status,
          },
        },
      };

    case TOTAL_TM_TRADEMARK_OPPONENT_FILTERS:
      if (action.status === LOADING) {
        if (action.pseudo) {
          return {
            ...state,
            pseudoTMState: {
              ...state.pseudoTMState,
              filters: {
                ...state.pseudoTMState.filters,
                TRADEMARK_OPPONENTFilterStatus: action.status,
                filterStatus: action.status,
              },
            },
          };
        }
        return {
          ...state,
          totalTMState: {
            ...state.totalTMState,
            filters: {
              ...state.totalTMState.filters,
              TRADEMARK_OPPONENTFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      if (action.pseudo) {
        return {
          ...state,
          pseudoTMState: {
            ...state.pseudoTMState,
            filters: {
              ...state.pseudoTMState.filters,
              TRADEMARK_OPPONENTFilterData: action.paginate
                ? [
                    ...new Set([
                      ...state.pseudoTMState.filters
                        .TRADEMARK_OPPONENTFilterData,
                      ...action.payload?.pseudo?.filters?.data,
                    ]),
                  ]
                : action.payload?.pseudo?.filters?.data,
              TRADEMARK_OPPONENTFilterDataCount:
                action.payload?.pseudo?.filters?.count || 0,
              TRADEMARK_OPPONENTFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      return {
        ...state,

        totalTMState: {
          ...state.totalTMState,
          filters: {
            ...state.totalTMState.filters,
            TRADEMARK_OPPONENTFilterData: action.paginate
              ? [
                  ...new Set([
                    ...state.totalTMState.filters.TRADEMARK_OPPONENTFilterData,
                    ...action.payload?.ip_india?.filters?.data,
                  ]),
                ]
              : action.payload?.ip_india?.filters?.data,
            TRADEMARK_OPPONENTFilterDataCount:
              action.payload?.ip_india?.filters?.count || 0,
            TRADEMARK_OPPONENTFilterStatus: action.status,
            filterStatus: action.status,
          },
        },
      };

    case TOTAL_TM_ALERTS_FILTERS:
      if (action.status === LOADING) {
        if (action.pseudo) {
          return {
            ...state,
            pseudoTMState: {
              ...state.pseudoTMState,
              filters: {
                ...state.pseudoTMState.filters,
                ALERTSFilterStatus: action.status,
                filterStatus: action.status,
              },
            },
          };
        }
        return {
          ...state,
          totalTMState: {
            ...state.totalTMState,
            filters: {
              ...state.totalTMState.filters,
              ALERTSFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      if (action.pseudo) {
        return {
          ...state,
          pseudoTMState: {
            ...state.pseudoTMState,
            filters: {
              ...state.pseudoTMState.filters,
              ALERTSFilterData: action.paginate
                ? [
                    ...new Set([
                      ...state.pseudoTMState.filters.ALERTSFilterData,
                      ...action.payload?.pseudo?.filters?.data,
                    ]),
                  ]
                : action.payload?.pseudo?.filters?.data,
              ALERTSFilterDataCount:
                action.payload?.pseudo?.filters?.count || 0,
              ALERTSFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      return {
        ...state,

        totalTMState: {
          ...state.totalTMState,
          filters: {
            ...state.totalTMState.filters,
            ALERTSFilterData: action.paginate
              ? [
                  ...new Set([
                    ...state.totalTMState.filters.ALERTSFilterData,
                    ...action.payload?.ip_india?.filters?.data,
                  ]),
                ]
              : action.payload?.ip_india?.filters?.data,
            ALERTSFilterDataCount:
              action.payload?.ip_india?.filters?.count || 0,
            ALERTSFilterStatus: action.status,
            filterStatus: action.status,
          },
        },
      };

    case TOTAL_TM_JOURNAL_FILTERS:
      if (action.status === LOADING) {
        if (action.pseudo) {
          return {
            ...state,
            pseudoTMState: {
              ...state.pseudoTMState,
              filters: {
                ...state.totalTMState.filters,
                JOURNALFilterStatus: action.status,
                filterStatus: action.status,
              },
            },
          };
        }
        return {
          ...state,
          totalTMState: {
            ...state.totalTMState,
            filters: {
              ...state.totalTMState.filters,
              JOURNALFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      if (action.pseudo) {
        return {
          ...state,
          pseudoTMState: {
            ...state.pseudoTMState,
            filters: {
              ...state.pseudoTMState.filters,
              JOURNALFilterData: action.paginate
                ? [
                    ...new Set([
                      ...state.pseudoTMState.filters.JOURNALFilterData,
                      ...action.payload?.pseudo?.filters?.data,
                    ]),
                  ]
                : action.payload?.pseudo?.filters?.data,
              JOURNALFilterDataCount:
                action.payload?.pseudo?.filters?.count || 0,
              JOURNALFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      return {
        ...state,

        totalTMState: {
          ...state.totalTMState,
          filters: {
            ...state.totalTMState.filters,
            JOURNALFilterData: action.paginate
              ? [
                  ...new Set([
                    ...state.totalTMState.filters.JOURNALFilterData,
                    ...action.payload?.ip_india?.filters?.data,
                  ]),
                ]
              : action.payload?.ip_india?.filters?.data,
            JOURNALFilterDataCount:
              action.payload?.ip_india?.filters?.count || 0,
            JOURNALFilterStatus: action.status,
            filterStatus: action.status,
          },
        },
      };

    case TOTAL_TM_TRADEMARK_OFFICE_FILTERS:
      if (action.status === LOADING) {
        return {
          ...state,
          totalTMState: {
            ...state.totalTMState,
            filters: {
              ...state.totalTMState.filters,
              TRADEMARK_OFFICEFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      if (action.pseudo) {
        return {
          ...state,
          pseudoTMState: {
            ...state.pseudoTMState,
            filters: {
              ...state.pseudoTMState.filters,
              TRADEMARK_OFFICEFilterData: action.paginate
                ? [
                    ...new Set([
                      ...state.pseudoTMState.filters.TRADEMARK_OFFICEFilterData,
                      ...action.payload?.pseudo?.filters?.data,
                    ]),
                  ]
                : action.payload?.pseudo?.filters?.data,
              TRADEMARK_OFFICEFilterDataCount:
                action.payload?.pseudo?.filters?.count || 0,
              TRADEMARK_OFFICEFilterStatus: action.status,
              filterStatus: action.status,
            },
          },
        };
      }
      return {
        ...state,

        totalTMState: {
          ...state.totalTMState,
          filters: {
            ...state.totalTMState.filters,
            TRADEMARK_OFFICEFilterData: action.paginate
              ? [
                  ...new Set([
                    ...state.totalTMState.filters.TRADEMARK_OFFICEFilterData,
                    ...action.payload?.ip_india?.filters?.data,
                  ]),
                ]
              : action.payload?.ip_india?.filters?.data,
            TRADEMARK_OFFICEFilterDataCount:
              action.payload?.ip_india?.filters?.count || 0,
            TRADEMARK_OFFICEFilterStatus: action.status,
            filterStatus: action.status,
          },
        },
      };

    case REFINED_TRADEMARK:
      return {
        ...state,
        refinedTrademark: action.payload,
        refinedTrademarkStatus: action.status,
      };

    case TRADEMARK_CHANGELOG:
      return {
        ...state,
        trademarkChangelog: action.payload,
        trademarkChangelogStatus: action.status,
      };

    case PROPRIETOR:
      return {
        ...state,
        proprietor: action.payload,
        proprietorStatus: action.status,
      };

    case PROPRIETOR_DETAIL:
      return {
        ...state,
        proprietorDetail: action.payload,
        proprietorDetailStatus: action.status,
      };

    case TLA_HEARING:
      return {
        ...state,
        tlaHearing: action.payload,
        tlaHearingStatus: action.status,
        upcomingHearings: action.upcomingHearings,
        tla: action.tla,
        tlaHearingLastAppliedFilters: action.lastAppliedFilters,
        tlaHearingPageNumber: action.pageNumber,
        report_columns: action.payload?.tla?.report_columns,
      };
    case OPPOSITION_HEARING_CLASSES_FILTERS:
      return {
        ...state,
        oppositionHearingClassFilters:
          action.payload?.opposition?.filters?.data || [],
        oppositionHearingFiltersStatus: action.status,
      };
    case OPPOSITION_HEARING_PROPRIETORS_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          oppositionHearingProprietorsFilters: action.paginate
            ? [
                ...new Set([
                  ...state.oppositionHearingProprietorsFilters,
                  ...action.payload?.opposition?.filters?.data,
                ]),
              ]
            : action.payload?.opposition?.filters?.data,
          oppositionHearingFiltersStatus: action.status,
          oppositionHearingProprietorsFiltersCount:
            action.payload?.opposition?.filters?.count,
        };
      } else {
        return {
          ...state,
          oppositionHearingFiltersStatus: action.status,
          oppositionHearingProprietorsFiltersCount:
            action.payload?.opposition?.filters?.count,
        };
      }
    case OPPOSITION_HEARING_LOCATION_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          oppositionHearingLocationFilters: action.paginate
            ? [
                ...new Set([
                  ...state.oppositionHearingLocationFilters,
                  ...action.payload?.opposition?.filters?.data,
                ]),
              ]
            : action.payload?.opposition?.filters?.data,
          oppositionHearingFiltersStatus: action.status,
          oppositionHearingLocationFiltersCount:
            action.payload?.opposition?.filters?.count,
        };
      } else {
        return {
          ...state,
          oppositionHearingFiltersStatus: action.status,
          oppositionHearingLocationFiltersCount:
            action.payload?.opposition?.filters?.count,
        };
      }
    case OPPOSITION_HEARING_OFFICERS_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          oppositionHearingOfficersFilters: action.paginate
            ? [
                ...new Set([
                  ...state.oppositionHearingOfficersFilters,
                  ...action.payload?.opposition?.filters?.data,
                ]),
              ]
            : action.payload?.opposition?.filters?.data,
          oppositionHearingFiltersStatus: action.status,
          oppositionHearingOfficersFiltersCount:
            action.payload?.opposition?.filters?.count,
        };
      } else {
        return {
          ...state,
          oppositionHearingFiltersStatus: action.status,
          oppositionHearingOfficersFiltersCount:
            action.payload?.opposition?.filters?.count,
        };
      }
    case OPPOSITION_HEARING_TAG_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          oppositionHearingTagFilters: action.paginate
            ? [
                ...new Set([
                  ...state.oppositionHearingTagFilters,
                  ...action.payload?.opposition?.filters?.data,
                ]),
              ]
            : action.payload?.opposition?.filters?.data,
          oppositionHearingFiltersStatus: action.status,
          oppositionHearingTagFiltersCount:
            action.payload?.opposition?.filters?.count,
        };
      } else {
        return {
          ...state,
          oppositionHearingFiltersStatus: action.status,
          oppositionHearingTagFiltersCount:
            action.payload?.opposition?.filters?.count,
        };
      }

    case OPPOSITION_HEARING_OFFICE_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          oppositionHearingTagFilters: action.paginate
            ? [
                ...new Set([
                  ...state.oppositionHearingOfficeFilters,
                  ...action.payload?.opposition?.filters?.data,
                ]),
              ]
            : action.payload?.opposition?.filters?.data,
          oppositionHearingFiltersStatus: action.status,
          oppositionHearingTagFiltersCount:
            action.payload?.opposition?.filters?.count,
        };
      } else {
        return {
          ...state,
          oppositionHearingFiltersStatus: action.status,
          oppositionHearingTagFiltersCount:
            action.payload?.opposition?.filters?.count,
        };
      }

    case TLA_HEARING_CLASSES_FILTERS:
      return {
        ...state,
        tlaHearingClassFilters: action.payload?.tla?.filters?.data || [],
        tlaHearingFiltersStatus: action.status,
      };
    case TLA_HEARING_PROPRIETORS_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          tlaHearingProprietorsFilters: action.paginate
            ? [
                ...new Set([
                  ...state.tlaHearingProprietorsFilters,
                  ...action.payload?.tla?.filters?.data,
                ]),
              ]
            : action.payload?.tla?.filters?.data,
          tlaHearingFiltersStatus: action.status,
          tlaHearingProprietorsFiltersCount:
            action.payload?.tla?.filters?.count,
        };
      } else {
        return {
          ...state,
          tlaHearingFiltersStatus: action.status,
          tlaHearingProprietorsFiltersCount:
            action.payload?.tla?.filters?.count,
        };
      }
    case TLA_HEARING_LOCATION_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          tlaHearingLocationFilters: action.paginate
            ? [
                ...new Set([
                  ...state.tlaHearingLocationFilters,
                  ...action.payload?.tla?.filters?.data,
                ]),
              ]
            : action.payload?.tla?.filters?.data,
          tlaHearingFiltersStatus: action.status,
          tlaHearingLocationFiltersCount: action.payload?.tla?.filters?.count,
        };
      } else {
        return {
          ...state,
          tlaHearingFiltersStatus: action.status,
          tlaHearingLocationFiltersCount: action.payload?.tla?.filters?.count,
        };
      }
    case TLA_HEARING_OFFICERS_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          tlaHearingOfficersFilters: action.paginate
            ? [
                ...new Set([
                  ...state.tlaHearingOfficersFilters,
                  ...action.payload?.tla?.filters?.data,
                ]),
              ]
            : action.payload?.tla?.filters?.data,
          tlaHearingFiltersStatus: action.status,
          tlaHearingOfficersFiltersCount: action.payload?.tla?.filters?.count,
        };
      } else {
        return {
          ...state,
          tlaHearingFiltersStatus: action.status,
          tlaHearingOfficersFiltersCount: action.payload?.tla?.filters?.count,
        };
      }
    case TLA_HEARING_OFFICE_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          tlaHearingOfficeFilters: action.paginate
            ? [
                ...new Set([
                  ...state.tlaHearingOfficeFilters,
                  ...action.payload?.tla?.filters?.data,
                ]),
              ]
            : action.payload?.tla?.filters?.data,
          tlaHearingFiltersStatus: action.status,
          tlaHearingOfficeFiltersCount: action.payload?.tla?.filters?.count,
        };
      } else {
        return {
          ...state,
          tlaHearingFiltersStatus: action.status,
          tlaHearingOfficeFiltersCount: action.payload?.tla?.filters?.count,
        };
      }
    case TLA_HEARING_TAG_FILTERS:
      if (action.status === "SUCCESS") {
        return {
          ...state,
          tlaHearingTagsFilters: action.paginate
            ? [
                ...new Set([
                  ...state.tlaHearingTagsFilters,
                  ...action.payload?.tla?.filters?.data,
                ]),
              ]
            : action.payload?.tla?.filters?.data,
          tlaHearingFiltersStatus: action.status,
          tlaHearingTagsFiltersCount: action.payload?.tla?.filters?.count,
        };
      } else {
        return {
          ...state,
          tlaHearingFiltersStatus: action.status,
          tlaHearingTagsFiltersCount: action.payload?.tla?.filters?.count,
        };
      }

    case OPPOSITION_HEARING:
      return {
        ...state,
        oppositionHearing: action.payload,
        oppositionHearingStatus: action.status,
        upcomingHearings: action.upcomingHearings,
        oppositionHearingLastAppliedFilters: action.lastAppliedFilters,
        oppositionHearingPageNumber: action.pageNumber,
        report_columns: action.payload?.opposition?.report_columns,
      };

    case OPPOSED_TLA_HEARING:
      return {
        ...state,
        opposedTlaHearing: action.payload,
        opposedTlaHearingStatus: action.status,
        upcomingHearings: action.upcomingHearings,
        tla: action.tla,
        opposedTlaHearingLastAppliedFilters: action.lastAppliedFilters,
        opposedTlaHearingPageNumber: action.pageNumber,
      };

    case OPPOSED_OPPOSITION_HEARING:
      return {
        ...state,
        opposedOppositionHearing: action.payload,
        opposedOppositionHearingStatus: action.status,
        upcomingHearings: action.upcomingHearings,
        opposedOppositionHearingLastAppliedFilters: action.lastAppliedFilters,
        opposedOppositionHearingPageNumber: action.pageNumber,
      };

    case MANAGE_TRADEMARK:
      return {
        ...state,
        manageTrademark: action.payload,
        manageTrademarkStatus: action.status,
        manageTrademarkType: action.trademarkType,
        manageTrademarkLastAppliedFilters: action.lastAppliedFilters,
        manageTrademarkPageNumber: action.pageNumber,
      };

    case POST_MANAGE_TRADEMARK:
      switch (action.status) {
        case SUCCESS:
          if (action.selectAll) {
            if (action.deleteFlag) {
              return {
                ...state,
                deleteManageTrademarkStatus: SUCCESS,
              };
            }
          } else {
            if (action.deleteFlag) {
              return {
                ...state,
                deleteManageTrademarkStatus: SUCCESS,
              };
            } else {
              return {
                ...state,
                manageTrademark: {
                  ...state.manageTrademark,
                  trademarks: state.manageTrademark.trademarks.map((eachRow) =>
                    eachRow.application_number ===
                    action.payload.mark.application_number
                      ? action.payload.mark
                      : eachRow,
                  ),
                },
                editManageTrademarkStatus: SUCCESS,
              };
            }
          }
          break;

        case LOADING:
          if (action.deleteFlag) {
            return {
              ...state,
              deleteManageTrademarkStatus: LOADING,
            };
          } else {
            return {
              ...state,
              editManageTrademarkStatus: LOADING,
            };
          }

        case ERROR:
          if (action.deleteFlag) {
            return {
              ...state,
              deleteManageTrademarkStatus: ERROR,
            };
          } else {
            return {
              ...state,
              editManageTrademarkStatus: ERROR,
            };
          }

        default:
          return state;
      }

      break;
    case UPLOAD_FILE_TM:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            manageTrademark: {
              ...state.manageTrademark,
              trademarks: state.manageTrademark.trademarks.map(
                (eachTrademark) => {
                  if (
                    eachTrademark.application_number ===
                    action.applicationNumber
                  ) {
                    return {
                      ...eachTrademark,
                      uploaded_documents: action.payload.uploaded_documents,
                    };
                  } else {
                    return eachTrademark;
                  }
                },
              ),
            },
          };

        case LOADING:
          return state;

        case ERROR:
          return state;

        default:
          return state;
      }

    case UPLOAD_FILE_TM_REFINED:
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            refinedTrademark: {
              ...state.refinedTrademark,
              ip_india: {
                ...state.refinedTrademark.ip_india,
                uploaded_documents: action.payload.uploaded_documents,
              },
            },
          };

        case LOADING:
          return state;

        case ERROR:
          return state;

        default:
          return state;
      }

    default:
      return state;
  }
}
